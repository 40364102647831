import { instance } from '../instance';
import { getApiUrl } from '@utils';

export async function createVisit({ placeId, tableId }) {
  const currentSession = JSON.parse(localStorage.getItem('session'));

  const requestData = {
    place: placeId,
    table: tableId,
  };

  const createSession = {
    method: 'post',
    url: `${getApiUrl()}/visits`,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
    data: requestData,
  };

  let res = currentSession?.visit;
  if (res && res.table && res && res.table.id !== tableId) {
    const destroySession = {
      method: 'delete',
      url: `${getApiUrl()}/visits/${currentSession.visit.id}`,
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    };

    await instance(destroySession);
  }

  if (!currentSession.visit) {
    const response = await instance(createSession);
    res = response.data.visit;
  }

  localStorage.setItem('psk', res.place.pspPK);
  return res;
}
