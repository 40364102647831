import { instance } from '../instance';
import { getApiUrl } from '@utils';

export async function fetchMenuStructure() {
  const requestConfig = {
    method: 'get',
    url: `${getApiUrl()}/menus/sections/available`,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  };

  const { data } = await instance(requestConfig);

  return data;
}

export async function fetchMenuItems(enabled, menuSections) {
  const requestConfig = {
    method: 'get',
    url: `${getApiUrl()}/menus/items`,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
    params: {
      enabled,
      menuSections,
    },
  };
  const { data } = await instance(requestConfig);

  return data;
}

export async function getMenuSections() {
  const requestConfig = {
    method: 'get',
    url: `${getApiUrl()}/menus/sections`,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  };

  const { data } = await instance(requestConfig);

  return data;
}

export async function updateMenuSection(sectionId, enabled) {
  const requestConfig = {
    method: 'patch',
    url: `${getApiUrl()}/menus/sections/${sectionId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
    data: {
      enabled: !enabled,
    },
  };

  const { data } = await instance(requestConfig);
  return data;
}

export async function fetchMenuSections() {
  const requestConfig = {
    method: 'get',
    url: `${getApiUrl()}/menus/items/available`,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  };

  const { data } = await instance(requestConfig);
  return data;
}

export async function fetchMenuServiceSections() {
  const requestConfig = {
    method: 'get',
    url: `${getApiUrl()}/menus/items`,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  };

  const { data } = await instance(requestConfig);

  return data;
}
export async function updateMenuItem(itemId, enabled) {
  const requestConfig = {
    method: 'patch',
    url: `${getApiUrl()}/menus/items/${itemId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
    data: {
      enabled: !enabled,
    },
  };

  const { data } = await instance(requestConfig);

  return data;
}
