import { instance } from '../instance';
import { getApiUrl } from '@utils';

export async function getProductDetails(productId) {
  const requestConfig = {
    method: 'get',
    url: `${getApiUrl()}/products/${productId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  };

  const { data } = await instance(requestConfig);
  return data;
}
