import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';
import { WithToastNotifications } from '@HOC';

import Router from '../../router/router';
import theme from '../../theme/theme';

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer autoClose={2500} />
      <Router />
    </MuiThemeProvider>
  );
}

export default WithToastNotifications(App);
