import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  screen: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: theme.colors.dark.darkSlateBlue,
  },
  loader: {
    width: 140,
    height: 140,
    position: 'relative',
  },
  text: {
    margin: 'auto',
    padding: '32px 28px',
    fontSize: '14pt',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 100,
    color: theme.colors.light.snow,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1,
  },
  screenLoading: {
    backgroundColor: 'rgba(38 50 56 / 0.9)',
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1400,
  },
}));

export const useStylesCircularProgress = makeStyles(theme => ({
  root: {
    width: '140px !important',
    height: '140px !important',
  },
  colorPrimary: {
    color: theme.colors.light.ghostWhite,
  },
}));
