import React, { useState } from 'react';
import { DrawerContext } from '@context';
function DrawerProvider({ children }) {
  const [openDrawer, setOpenDrawer] = useState(true);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  return (
    <DrawerContext.Provider value={{ openDrawer, toggleDrawer }}>
      {children}
    </DrawerContext.Provider>
  );
}

export default DrawerProvider;
