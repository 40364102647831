import { instance } from '../instance';
import { getApiUrl } from '@utils';

export async function createOrder(orderData) {
  const { products, instructions } = orderData;
  console.log(products);
  const requestData = JSON.stringify({
    items: products,
    instructions,
  });
  const requestConfig = {
    method: 'post',
    url: `${getApiUrl()}/orders`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: requestData,
  };

  const { data: response } = await instance(requestConfig);
  return response;
}
