import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import LoadingSpinner from '@components/LoadingSpinner';
import { ROUTES, purposesLoadingSpinner } from '@constants';

export const routeConfig = [
  {
    component: React.lazy(() =>
      import(/* webpackPrefetch: true */ `../pages/SignIn`),
    ),
    name: ROUTES.SIGN_IN_SCREEN,
    exact: true,
    path: ROUTES.SIGN_IN_SCREEN,
  },
  {
    component: React.lazy(() =>
      import(/* webpackPrefetch: true */ `../pages/CheckCovidScreen`),
    ),
    name: ROUTES.CHECK_COVID_SCREEN,
    exact: true,
    path: ROUTES.CHECK_COVID_SCREEN,
  },
  {
    component: React.lazy(() => import(`../pages/StartScreen`)),
    exact: true,
    path: ROUTES.START_SCREEN,
  },
  {
    component: React.lazy(() =>
      import(/* webpackPrefetch: true */ `../pages/SliderScreen`),
    ),
    name: ROUTES.SLIDER_SCREEN,
    exact: true,
    path: ROUTES.SLIDER_SCREEN,
  },
  {
    component: React.lazy(() =>
      import(/* webpackPrefetch: true */ `../pages/AdviceScreen`),
    ),
    name: ROUTES.ADVICE_SCREEN,
    exact: true,
    path: ROUTES.ADVICE_SCREEN,
  },
  {
    component: React.lazy(() =>
      import(/* webpackPrefetch: true */ `../pages/MenuPage`),
    ),
    name: ROUTES.MENU_SCREEN,
    exact: true,
    path: ROUTES.MENU_SCREEN,
  },
  {
    component: React.lazy(() =>
      import(/* webpackPrefetch: true*/ `../pages/OrdersScreen`),
    ),
    name: ROUTES.ORDERS_SCREEN,
    exact: true,
    path: ROUTES.ORDERS_SCREEN,
  },
  {
    component: React.lazy(() =>
      import(/* webpackPrefetch: true*/ `../pages/OccasionScreen`),
    ),
    name: ROUTES.OCCASION_SCREEN,
    exact: true,
    path: ROUTES.OCCASION_SCREEN,
  },
  {
    component: React.lazy(() =>
      import(/* webpackPrefetch: true */ `../pages/ThankYouScreen`),
    ),
    name: ROUTES.THANK_YOU_SCREEN,
    exact: true,
    path: ROUTES.THANK_YOU_SCREEN,
  },
  {
    component: React.lazy(() =>
      import(/* webpackPrefetch: true */ `../pages/AccountSettingsScreen`),
    ),
    name: ROUTES.ACCOUNT_SETTINGS_SCREEN,
    exact: true,
    path: ROUTES.ACCOUNT_SETTINGS_SCREEN,
  },
  {
    component: React.lazy(() =>
      import(/* webpackPrefetch: true */ `../pages/DrinksHistoryScreen`),
    ),
    name: ROUTES.DRINKS_HISTORY_SCREEN,
    exact: true,
    path: ROUTES.DRINKS_HISTORY_SCREEN,
  },
  {
    component: React.lazy(() =>
      import(/* webpackPrefetch: true */ `../pages/ErrorScreen`),
    ),
    name: ROUTES.ERROR_SCREEN,
    exact: true,
    path: ROUTES.ERROR_SCREEN,
  },
  {
    component: React.lazy(() =>
      import(/* webpackPrefetch: true */ `../pages/ErrorVisitScreen`),
    ),
    name: ROUTES.ERROR_VISIT_SCREEN,
    exact: true,
    path: ROUTES.ERROR_VISIT_SCREEN,
  },
  {
    component: React.lazy(() =>
      import(/* webpackPrefetch: true */ `../pages/VerificationCodeScreen`),
    ),
    name: ROUTES.VERIFICATION_SCREEN,
    exact: true,
    path: ROUTES.VERIFICATION_SCREEN,
  },
  {
    component: React.lazy(() =>
      import(
        /* webpackPrefetch: true */ `../pages/PointServiceScreens/POSRouter`
      ),
    ),
    name: ROUTES.POINT_OF_SERVICE_ROUTER,
    exact: true,
    path: ROUTES.POINT_OF_SERVICE_ROUTER,
  },
  {
    component: React.lazy(() =>
      import(
        /* webpackPrefetch: true */ `../pages/PointServiceScreens/POSOrders`
      ),
    ),
    name: ROUTES.POINT_OF_SERVICE_ORDERS,
    exact: true,
    path: ROUTES.POINT_OF_SERVICE_ORDERS,
  },
  {
    component: React.lazy(() =>
      import(/* webpackPrefetch: true */ `../pages/VisitScreen`),
    ),
    name: ROUTES.VISIT_SCREEN,
    exact: true,
    path: ROUTES.VISIT_SCREEN,
  },
  {
    component: React.lazy(() =>
      import(/* webpackPrefetch: true */ `../pages/AuthScreen`),
    ),
    name: ROUTES.AUTH_SCREEN,
    exact: true,
    path: ROUTES.AUTH_SCREEN,
  },
  {
    component: React.lazy(() =>
      import(/* webpackPrefetch: true */ `../pages/VerifyEmailScreen`),
    ),
    name: ROUTES.VERIFY_EMAIL_SCREEN,
    exact: true,
    path: ROUTES.VERIFY_EMAIL_SCREEN,
  },
  {
    component: React.lazy(() =>
      import(/* webpackPrefetch: true */ `../pages/ManageMenu`),
    ),
    name: ROUTES.MENU_MANAGEMENT,
    exact: true,
    path: ROUTES.MENU_MANAGEMENT,
  },
  {
    component: React.lazy(() => import('../pages/NotFoundScreen')),
    name: 'notfound',
    path: '*',
  },
];

const Router = () => {
  return (
    <BrowserRouter>
      <Suspense
        fallback={<LoadingSpinner purpose={purposesLoadingSpinner.regular} />}
      >
        <Switch>
          {routeConfig
            .filter(route => route.component)
            .map((route, i) => (
              <Route key={i} {...route} />
            ))}
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
