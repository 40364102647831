import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  fonts: {
    family: {
      primary: 'Roboto',
      secondary: 'Orbitron',
    },
    sizes: {
      font9PX: 9,
      font10PX: 10,
      font12PX: 12,
      font13PX: 13,
      font14PX: 14,
      font16PX: 16,
      font18PX: 18,
      font20PX: 20,
      font24PX: 24,
    },
  },
  colors: {
    light: {
      snow: '#F3F4FA',
      lightCyan: '#BECDE0',
      ghostWhite: '#C5D3DF',
      freshPowder: '#FFFFFF',
      lightSteelBlue: '#78879A',
    },
    dark: {
      grayBlue: '#6a7482',
      gainsboro: '#EBEDF5',
      darkBlue: '#303C50',
      darkNavy: '#1A222F',
      steelBlue: '#43566C',
      darkMask: '#020714b3',
      midnightBlue: '#171C2C',
      darkSlateBlue: '#232838',
      darkSlateGray: '#020714',
    },
    orange: {
      wheat: '#FFE6C0',
      orangered: '#FFA723',
      orange: '#FF9B07',
      darkOrange: '#FF8D07',
      peru: '#E99D20',
      orangeSemiTransparent: '#ff9b0733',
    },
    red: '#FF1717',
  },
});
